<template>
    <nav class="footer-bar">
        <ul>
            <li :class="{active: current === 'index'}">
                <router-link to="/live/index" replace>
                    <i class="iconfont icon-banner"></i>
                    <div class="footer-bar-item-ft">遇见小课</div>
                </router-link>
            </li>
            <li :class="{active: current === 'my'}">
                <router-link to="/live/my" replace>
                    <i class="iconfont icon-me"></i>
                    <div class="footer-bar-item-ft">我的课程</div>
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: 'LiveFooterBar',
        props: ['current'],
        data () {
            return {}
        }
    }
</script>

<style scoped lang="less">
    @import "../less/_variables_live";
    @import "../less/_mixin";

    .footer-bar {
        @color: @text-color;
        @active-color: @colorPrimary;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: @base-zindex + 1;
        background-color: @colorGray1;
        min-height: @bottom-bar-height;
        padding-bottom:calc( constant(safe-area-inset-bottom));
        padding-bottom:calc( env(safe-area-inset-bottom));

        &:before {
            .setTopLine(@colorGray3);
        }

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
        }

        li {
            flex: 1;

            a {
                position: relative;
            }
        }

        li, a {
            display: block;
        }

        a {
            text-align: center;
            color: @color;

            padding-top: 6px;
            line-height: 18px;
            font-size: 12px;

            position: relative;
            .iconfont {
                color: @color;
                font-size: 18px;
            }
        }

        .active a {
            color: @active-color;
            .iconfont {
                color: @active-color;
            }
        }

        .footer-bar-item-ft {
            transform: scale(0.9);
        }
    }
</style>
