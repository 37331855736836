import Ajax from '@/utils/ajax'
import AjaxForNative from '@/utils/ajaxForNative'
import Service from '@/service'
import Tokens from './tokens'
import Detect from '@/utils/detect'
import Api from './api'

const entryUrl = location.href.split('#')[0]

export default class {
    constructor (id, Vue) {
        this.Vue = Vue
        this.eventBus = Vue.prototype.$eventBus
        this._siteState = {}
        this._msgState = null
        this.$el = document.getElementById(id)
        this._login = false
        this._ajax = null
        this._baseUrl = location.protocol + '//' + location.hostname + (location.port === '' ? '' : (':' + location.port)) + '/'
        this._isWeixin = Detect.os.weixin
    }

    get siteState () {
        return this._siteState
    }

    get msgState () {
        if (!this._msgState) {
            this._msgState = this.ajax.get(Service.message_has_new).then(res => {
                if (res.code === 200) {
                    return res.data
                }
            }).catch(() => {
                return 0
            })
        }
        return this._msgState
    }

    resetMsgState () {
        this.msgState = Promise.resolve(0)
        this.ajax.post(Service.message_read_all)
        this.eventBus.$emit('msgStateChange', 0)
    }

    set msgState (v) {
        this._msgState = v
    }

    get baseUrl () {
        return this._baseUrl
    }

    get isWeixin () {
        return this._isWeixin
    }

    getUrl (url) {
        url = url.replace(/^\//, '')
        return this.baseUrl + url
    }

    get currentPage () {
        return this._page
    }

    set currentPage (_page) {
        this._page = _page
    }

    get ajax () {
        return this._ajax
    }

    set ajax (_ajax) {
        this._ajax = _ajax
    }

    get logon () {
        return this._login
    }

    set logon (_login) {
        const old = this._login
        this._login = _login
        if (_login !== old) {
            this.eventBus.$emit('authChange', _login)
        }
    }

    get isNative () {
        return !!this.native
    }

    injectNative (native) {
        this.native = native
        this.reBindAjax()
    }

    get needLogin () {
        return !!this._needLogin
    }

    set needLogin (_needLogin) {
        this._needLogin = _needLogin
    }

    injectVue (router) {
        this.router = router

        router.beforeEach((to, from, next) => {
            if (to.meta.detect !== false &&
                (process.env.VUE_APP_NAME === 'online') &&
                Detect.os.android &&
                !Detect.os.weixin &&
                to.name !== 'download_android' &&
                !this.isNative) {
                // 安卓非微信浏览器、非allyoga的app内打开，也跳到/about页面
                next({
                    name: 'download_android',
                    replace: true
                })
            } else if (to.matched.some(record => record.meta.needLogin)) {
                if (!this.logon) {
                    this.needLogin = true
                    // next(this.needLoginNext(to));
                    this.goAuth()
                } else {
                    this.needLogin = false
                    next()
                }
            } else {
                next()
            }
        })
    }

    reBindAjax () {
        if (this.isNative) {
            // native
            this.ajax = {};
            ['get', 'post'].forEach((method) => {
                this.ajax[method] = ((method) => {
                    return (...args) => {
                        return new Promise((resolve, reject) => {
                            this.native.getNativeRequestInfo({
                                url: args[0]
                            }, {
                                success: (requestInfo) => {
                                    const headers = {
                                        Accept: requestInfo.accept,
                                        SIGN: requestInfo.sign,
                                        'APP-VERSION': requestInfo.app_version,
                                        'OS-NAME': requestInfo.os_name,
                                        'CLIENT-ID': requestInfo.client_id
                                    }

                                    if (requestInfo.access_token) {
                                        headers.Authorization = 'Bearer ' + requestInfo.access_token
                                    }

                                    if (args.length === 1) {
                                        args.push({})
                                        args.push({})
                                    } else if (args.length === 2) {
                                        args.push({})
                                    }
                                    args[2].headers = headers

                                    AjaxForNative[method](...args).then(res => {
                                        if (res.code === 401) {
                                            this.native.callNativeLogin()
                                        }

                                        resolve(res)
                                    }).catch(reject)
                                }
                            })
                        })
                    }
                })(method)
            })
        } else {
            // 非native
            this.ajax = Ajax

            const that = this;
            ['get', 'post'].forEach((method) => {
                const old = this.ajax[method]
                this.ajax[method] = function (...args) {
                    return old.call(this.ajax, ...args).then((res) => {
                        if (res.code === 401) {
                            that.logon = false
                            that.goAuth()
                        } else if (res.code === 404) {
                            that.router.replace({ name: '404' })
                        }
                        return res
                    }).finally((...args) => {
                    })
                }
            })
        }
    }

    get isMini () {
        return window.__wxjs_environment === 'miniprogram'
    }

    async initState () {
        const miniOpenid = sessionStorage.getItem('mini_openid') || ''
        const appAuth = sessionStorage.getItem('app_auth') || ''

        if (appAuth) {
            console.log('enter from app auth')
            sessionStorage.removeItem('app_auth')
            await this.ajax.get(Service.app_auth_token_get, {
                app_auth: appAuth,
                client_id: Api.getClientId()
            }).then((res) => {
                if (res.code === 200 && res.data) {
                    this.setTokens(res.data)
                }
            })
        }
        const data = await this.ajax.get(Service.site_state, {
            miniOpenid,
            app: 'allyoga'
        }).then(res => {
            if (res.code === 200) {
                if (res.data.tokens) {
                    this.setTokens(res.data.tokens)
                }

                this._siteState = res.data
                this.logon = this._siteState.login

                return res.data
            } else {
                this.$el.innerText = '应用初始化失败'

                return null
            }
        }).catch(() => {
            this.$el.innerText = '应用初始化请求失败'

            return null
        })

        return data
    }

    setTokens (tokens) {
        Tokens.fill(tokens)
        this.eventBus.$emit('tokensFilled')
        this.logon = true
    }

    activateFreeVip () {
        return this.ajax.post(Service.activate_free_vip)
    }

    /* needLoginNext(route) {
            localStorage.REDIRECT_ROUTE_AFTER_AUTH = JSON.stringify({
                name: route.name,
                path: route.path,
                query: route.query,
                params: route.params
            });
            return {name: 'login'};
        } */

    goAuth () {
        if (this.router.currentRoute.name === 'login') return

        if (this.isNative) {
            this.native.callNativeLogin({}, {
                success: () => {
                }
            })
        } else if (this.isMini && sessionStorage.mini_openid) {
            this.ajax.post(Service.mini_token_clear_post, {
                openid: sessionStorage.mini_openid,
                app: 'allyoga'
            }).then(res => {
                if (res.code === 200) {
                    window.wx.miniProgram.navigateTo({
                        url: '/pages/l/index'
                    })
                    this.checkMiniLogonStatus()
                }
            })
        } else {
            location.href = process.env.VUE_APP_PASSPORT_URL
        }
    }

    checkMiniLogonStatus () {
        const c = async () => {
            const res = await this.ajax.get(Service.mini_token_get, {
                openid: sessionStorage.mini_openid,
                app: 'allyoga'
            })

            if ((res.code === 200 && res.data) || res.code === 403) {
                if (res.code === 200) {
                    this.setTokens(res.data)
                }
                clearTimeout(this._checkMiniTimer)
                this._checkMiniTimer = 0
                return
            }
            this._checkMiniTimer = setTimeout(c, 1500)
        }
        if (!sessionStorage.mini_openid) {
            return
        }
        if (this._checkMiniTimer) {
            return
        }
        this._checkMiniTimer = setTimeout(c, 1500)
    }

    // 微信openid获取相关 start
    getOpenidRedirectUrl (provider) {
        return this.baseUrl + 'pay/get_openid?provider=' + provider + '&rnd=' + Math.random()
    }

    goGetOpenid (provider, jump = true) {
        if (provider === 'weixin') {
            sessionStorage.oauth_device = process.env.VUE_APP_OAUTH_DEVICE
            return this.ajax.get(Service.weixin_auth_url + '?url=' + encodeURIComponent(this.getOpenidRedirectUrl(provider)), {
                device: sessionStorage.oauth_device,
                scope: 'snsapi_base'
            }).then((res) => {
                if (res.code === 200) {
                    res.data.state_id && window.sessionStorage.setItem('oauth_state_id', res.data.state_id)
                    if (jump) {
                        sessionStorage.HISTORY_LENGTH_BEFORE_OAUTH = history.length
                        location.href = res.data.url
                    }
                }

                return res
            })
        }
    }

    getOpenid () {
        const query = this.router.currentRoute.query
        const provider = query.provider

        if (provider === 'weixin') {
            const code = query.code
            const state = query.state

            if (code && window.sessionStorage.getItem('last_get_openid_code') !== code) {
                // 控制code只能使用一次
                window.sessionStorage.setItem('last_get_openid_code', code)
                this.currentPage.showWaiting({ text: '微信核验...' })

                const complete = toast => {
                    this.currentPage.showToast({
                        ...toast,
                        onDismiss: () => {
                            this.exitGetOpenid()
                        }
                    })
                }

                const params = {
                    state_id: window.sessionStorage.getItem('oauth_state_id') || '',
                    code: code,
                    state: state,
                    device: sessionStorage.oauth_device
                }

                return this.ajax.get(Service.weixin_openid, params).then((res) => {
                    if (res.code === 200) {
                        sessionStorage.openid = res.data
                        localStorage.openid = res.data
                        this.exitGetOpenid()
                    } else {
                        return complete({ text: '微信核验失败' })
                    }
                }).catch(function () {
                    return complete({ text: '微信核验出错' })
                }).finally(() => {
                    window.sessionStorage.removeItem('oauth_state_id')
                    this.currentPage.hideWaiting()
                })
            } else {
                this.goGetOpenid(provider, false).then(res => {
                    if (res.code === 200) {
                        // 直接跳转目标地址 不再调用router.back 见goOauth方法内的描述
                        location.href = res.data.url
                    }
                })
            }
        }
    }

    exitGetOpenid () {
        let l = 0
        if (sessionStorage.HISTORY_LENGTH_BEFORE_OAUTH) {
            l = parseInt(sessionStorage.HISTORY_LENGTH_BEFORE_OAUTH)
        }

        if (l < history.length) {
            // 微信的history.length控制有问题 导致必须用l < history.length的判断
            // 正常情况下history.length肯定是大于l的
            // 但是在微信内出现history.length小于l的情况
            this.router.go(-1 * (history.length - l))
        } else {
            this.router.go(-1)
        }
    }

    // 微信openid获取相关 end

    async logout () {
        this.currentPage && this.currentPage.showLoading()
        await this.ajax.post(Service.user_log_out)
        this.currentPage && this.currentPage.hideLoading()
        Tokens.clear()
        this.logon = false
        this.unCheckPractiseLogCount = 0
    }

    async logoutAndReload () {
        this.currentPage && this.currentPage.showLoading()
        await this.ajax.post(Service.user_log_out)
        this.currentPage && this.currentPage.hideLoading()
        Tokens.clear()
        this.logon = false
        this.unCheckPractiseLogCount = 0
        location.reload()
    }

    isIOS () {
        return !!Detect.os.ios
    }

    canOpenAppFromWeb () {
        if (/(video\/detail|series\/detail|series\/video)/g.test(location.pathname)) {
            return true
        }
        return false
    }

    goDownload (eventId, path = '', search = '') {
        if (this.isMini) {
            window.alert('该功能需要安装APP才能使用，请您前往应用商店下载安装“全是瑜”')
            return
        }
        if (!path) {
            path = location.pathname
        }
        if (!search) {
            search = location.search
        } else {
            if (search.indexOf('?') === -1) {
                search = '?' + search
            }
        }
        location.href = `https://universal.yoga1314.com/jump${path}${search}`
    }

    callWxPay (config, opts) {
        const options = {
            ...{
                success: () => {
                },
                cancel: () => {
                },
                error: () => {
                }
            },
            ...opts
        }

        const jsApiCall = () => {
            window.WeixinJSBridge.invoke(
                'getBrandWCPayRequest', config,
                (res) => {
                    if (res.err_msg === 'get_brand_wcpay_request:ok') {
                        options.success()
                    } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
                        options.cancel()
                    } else {
                        options.fail(res)
                    }
                }
            )
        }

        if (typeof window.WeixinJSBridge === 'undefined') {
            if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', jsApiCall, false)
            } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', jsApiCall)
                document.attachEvent('onWeixinJSBridgeReady', jsApiCall)
            }
        } else {
            jsApiCall()
        }
    }

    isWxPayDirectory () {
        let target = entryUrl
        if (Detect.os.android) {
            target = location.href.split('#')[0]
        }
        return (/\/pay\/(vip|series|vip-new|series_group_buy|mooc-order|live-course)\?/g).test(target)
    }

    isFirstPage ($route) {
        const routes = this.Vue.navigation.getRoutes()
        const routeKey = this.Vue.navigation.getRouteKey($route)
        return routeKey === routes[0]
    }

    getPrevRouteName () {
        const routes = this.Vue.navigation.getRoutes()
        const routeKey = this.Vue.navigation.getRouteKey(this.router.currentRoute)
        const prevRouteKey = routes.findIndex(item => item === routeKey) - 1
        if (prevRouteKey > -1) {
            return routes[prevRouteKey].split('?')[0]
        }
        return ''
    }

    getLocalLink (link) {
        return link.replace(/http(s)?:\/\/.+\.com(\.cn)?(:\d+)?\//g, '/')
    }

    jumpLink (targetUrl, newPage = true) {
        if (!targetUrl) return
        const link = targetUrl.replace('allyoga://yoga1314.com/', this.baseUrl)

        if (link.indexOf('//' + location.hostname) > -1) {
            this.router.push({
                path: this.getLocalLink(link)
            })
        } else if (this.isMini && newPage) {
            window.wx.miniProgram.navigateTo({
                url: `/pages/web/index?url=${encodeURIComponent(link)}`
            })
        } else if (this.isNative && newPage) {
            return this.native.openNativeWebView({
                link: link,
                title: ''
            })
        } else {
            location.href = link
        }
    }
}
