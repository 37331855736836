import Detect from './detect'
import HybridSdk from 'hybrid-jssdk-backbone'

const sdk = new HybridSdk({
    debug: true,
    isNative () {
        return (!!window.sessionStorage.allyogaNative && !Detect.os.weixin) || Detect.os.native
    }
})

sdk.registerApi('setNativeShareInfo', {
    afterInvoke
})

sdk.registerApi('setNativeShareReportData', {
    afterInvoke,
    beforeInvoke (apiName, params) {
        return Object.assign({
            module: '',
            data: ''
        }, params)
    }
})

sdk.registerApi('isNativeLogin', {
    afterInvoke (apiName, nativeData) {
        nativeData.value = nativeData.message
        nativeData.message = `${apiName}:ok`
    }
})

sdk.registerApi('dispatchNativeNotice', {
    afterInvoke,
    beforeInvoke (apiName, params) {
        return {
            notice: params.notice
        }
    }
})

sdk.registerApi('openNativeView', {
    afterInvoke,
    beforeInvoke (apiName, params) {
        return {
            link: params.link
        }
    }
})

sdk.registerApi('openSharePreviewView', {
    afterInvoke,
    beforeInvoke (apiName, params) {
        return {
            image: params.image
        }
    }
})

sdk.registerApi('openNativeWebView', {
    afterInvoke,
    beforeInvoke (apiName, params) {
        return {
            link: params.link,
            title: params.title,
            close: params.close === true
        }
    }
})

sdk.registerApi('setNativeTitle', {
    afterInvoke,
    beforeInvoke (apiName, params) {
        return {
            title: params.title
        }
    }
})

sdk.registerApi('setNativeStorage', {
    afterInvoke,
    beforeInvoke (apiName, params) {
        return {
            key: params.key,
            value: params.value
        }
    }
})

sdk.registerApi('openWechatMiniProgram', {
    afterInvoke,
    beforeInvoke (apiName, params) {
        return {
            origin_id: params.origin_id + '',
            path: params.path + '',
            mini_type: params.mini_type + ''
        }
    }
})

sdk.registerApi('getNativeStorage', {
    afterInvoke (apiName, nativeData) {
        nativeData.value = nativeData.message
        nativeData.message = `${apiName}:ok`
    },
    beforeInvoke (apiName, params) {
        return { key: params.key }
    }
})

sdk.registerApi('getNativeRequestInfo', {
    afterInvoke,
    beforeInvoke (apiName, params) {
        let url = params.url
        if (url.indexOf('?') > -1) {
            url = url.split('?')[0]
        }

        return { url }
    }
})

sdk.registerApi('callNativeLogin', {
    afterInvoke
})

sdk.registerApi('callNativeShare', {
    afterInvoke
})

sdk.registerApi('callNativeWxPay', {
    afterInvoke (apiName, nativeData) {
        if (nativeData.code + '' === '0') {
            nativeData.message = `${apiName}:ok`
        } else if (nativeData.code + '' === '-2') {
            nativeData.message = `${apiName}:cancel`
        } else {
            nativeData.message = `${apiName}:error`
        }
    }
})

sdk.registerApi('callClose', {
    afterInvoke
})

function afterInvoke (apiName, nativeData) {
    Object.assign(nativeData, {
        message: `${apiName}:ok`
    })
}

export default sdk
