<template>
    <popup-component v-model="popupVisible"
                     @input="syncPopupModelProp"
                     :close-btn="false"
                     position="bottom"
                     content-width="100%"
                     v-bind="$attrs">
        <div class="action_sheet">
            <div class="action_sheet_bd">
                <a v-for="(item,index) in items" :key="index" href="javascript:"
                   @click.prevent="click(item)" class="action_cell" :class="{
                   active: item.selected
                   }">{{item.text}}</a>
            </div>
            <div class="action_sheet_ft">
                <a href="javascript:" @click.prevent.stop="dismiss"
                   class="action_cell">{{cancelText}}</a>
            </div>
        </div>
    </popup-component>
</template>
<script>
    import PopupUtils from './PopupUtils'

    export default {
        name: 'ActionSheet',
        mixins: [PopupUtils],
        props: {
            items: {
                type: Array,
                default: () => []
            },
            cancelText: {
                type: String,
                default: '取消'
            }
        },
        methods: {
            click (item) {
                this.$emit('select', item)
                this.dismiss()
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../less/_variables";
    @import "../less/_mixin";

    .action_sheet {
        line-height: 1.6;

        .action_sheet_bd {
            .action_cell {
                &:first-child {
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }
            }
        }

        .action_cell {
            padding: 12px 0;
            text-align: center;
            font-size: 14px;
            display: block;
            color: @text-color;
            position: relative;
            background-color: @white;

            &.active {
                color: @colorPrimary;
            }

            &.active {
                color: @brand-primary;
            }

            & + .action_cell {
                &:before {
                    .setTopLine(@colorGray3);
                    left: 10px;
                    right: 10px;
                    width: auto;
                }
            }
        }

        .action_sheet_ft {
            border-top: 6px solid #EFEFF4;

            .action_cell {
                padding-bottom:calc( constant(safe-area-inset-bottom));
                padding-bottom:calc( env(safe-area-inset-bottom));
            }
        }
    }
</style>
